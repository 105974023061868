.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  background-color: black;
  height: 50px;
  width: 200px;
}

.warning-container {
  width: 70%;
  height: fit-content;
  padding: 20px;
  border: 4px solid rgb(127 133 245);
  border-radius: 12px;
}

.warning{
  color: red;
  font-size: 28px;
  vertical-align: middle;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.actions-container {
  display: flex;
  flex-direction: column;
}

.actions-status {
  font: bold 24px helvetica, sans-serif;
}

.runningStatus {
  color: green;
}

.stoppedStatus {
  color: orange;
}

.button {
  background-color: rgb(127 133 245); 
  padding: 12px 10px;
  margin: 12px;
  color: white;
  border-radius: 6px;
  font: bold 16px helvetica, sans-serif;
  border: none;
}

.button:hover {
  background-color: rgb(143 143 143);
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
